@import "config";
@import "stylesheets/table";
@import "stylesheets/metronic/style.angular";

// cuco loader Animation Styles
@import "stylesheets/loading/loading";

//@import "ngx-bootstrap/datepicker/utils/scss/variables";
//@import "ngx-bootstrap/datepicker/bs-datepicker";
@import "../node_modules/ngx-bootstrap/datepicker/utils/scss/variables";

// Metronic Styles
@import "stylesheets/metronic/themes/layout/aside/light";
@import "stylesheets/metronic/themes/layout/brand/light";
@import "stylesheets/metronic/themes/layout/header/base/light";
@import "stylesheets/metronic/themes/layout/header/menu/light";
@import "stylesheets/metronic/pages/login/login-4";
//@import "stylesheets/metronic/pages/wizard/wizard-3";
@import "stylesheets/project";
@import "@ng-select/ng-select/themes/default.theme.css";

