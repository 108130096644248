.dropdown-toggle::after {
	display: none !important;
}

.dropdown-fluid {
	height: 100%;
	.topbar-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

.svg-icon.svg-icon-light-brand-hover svg:hover g [fill],
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon.svg-icon-light-brand-hover svg:hover g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon.svg-icon-light-brand-hover svg g [fill],
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon.svg-icon-light-brand-hover svg g [fill] {
	fill: theme-color('brand');
}
