//
// Loading
//

.dialog {
	$width: 130px;
	width: $width;
	margin: 0 auto;
	position: fixed;
	z-index: 1100;
	justify-content: center;
	padding: 1rem;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	@include border-radius($border-radius);

	// Placement
	&.dialog-top-center {
		display: flex;
		top: -100px;
		left: 50%;
		margin-left: -(calc($width / 2));
		transition: top 0.6s ease;
		@include border-top-left-radius(0);
		@include border-top-right-radius(0);

		&.dialog-shown {
			top: 0;
			transition: top 0.6s ease;
		}
	}

	// Types
	&.dialog-loader {
		font-size: 1rem;
		padding: 0.75rem;
		font-weight: 400;
		color: $dark-75;
	}
}
