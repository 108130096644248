@import "../config";

p-table .p-datatable {
	font-family: unset;
	line-height: unset;
	font-weight: unset;

	table {
		width: 100%;

		.p-datatable-thead tr {
			th {
				border-color: #EBEDF3 !important;
				border-top: 0 solid transparent;

				outline-color: $cuco-brand-color;

				padding: 10px 6px;
				font-weight: 400;

				//input {
				//	//border: 0 solid transparent;
				//	max-width: 100%;
				//}

				p-multiSelect, .p-column-filter, .p-multiselect-label-container, .p-multiselect-label {
					width: 100%;
				}

				.p-multiselect-header {
					//background-color: #F3F6F9;
					background-color: white;
				}

				&.p-sortable-column {
					&:focus {
						box-shadow: inset 0 0 0 0.2rem $cuco-brand-color;
					}

					&.p-highlight {
						color: $cuco-brand-color;

						.p-sortable-column-icon {
							color: $cuco-brand-color;
						}
					}
				}
			}

			&:last-child th {
				border-bottom: 1px solid #EBEDF3;
			}
		}

		.p-datatable-tbody tr {
			td {
				padding: 10px 6px;
				border-bottom: 1px solid #EBEDF3;
			}
		}

		.p-checkbox-box {
			background-color: #EBEDF3;
			border: 0;
			//height: 18px;
			//width: 18px;
			&.p-highlight {
				background-color: #3699FF !important;
			}

			.p-checkbox-icon {
				width: 100%;
				height: 100%;
				text-align: center;
				font-size: 16px;
			}
		}

		//.ui-multiselect-panel {
		//	background-color: #FFF;
		//	min-width: 200px;
		//
		//	.ui-chkbox {
		//		margin-right: 1em;
		//	}
		//}
	}

	.p-paginator-bottom {
		color: #7E8299;
		justify-content: start;
		float: left;
		margin-top: 15px;
		height: auto;
		width: 100%;
		border: 0 solid transparent;

		.p-link:focus {
			box-shadow: 0 0 transparent;
		}

		.p-paginator-pages {
			height: auto;
			float: left;

			.p-paginator-page {
				color: #7E8299;
			}
		}


		.p-paginator-element {
			border: 0 solid transparent;
			border-radius: 0.42rem;
			height: 2.25rem;
			min-width: 2.25rem;
			font-size: 1rem;
			line-height: 1rem;
			padding: .5rem;
			float: left;
			margin-right: 5px;


			&.p-highlight {
				background: $cuco-brand-color;
				color: #fff;
			}

			&.p-link:not(.p-disabled):hover {
				background-color: $cuco-brand-color;
				color: white;
			}

			&.p-paginator-last,
			&.p-paginator-next,
			&.p-paginator-prev,
			&.p-paginator-first {
				background-color: #ecf1f6;
				color: #7E8299;

				&.p-disabled {
					opacity: .6;
				}
			}
		}

		p-dropdown {
			flex-grow: 1;
		}

		.p-dropdown {
			border: 0 solid transparent;
			float: right;
			background-color: rgb(240, 243, 255);
			height: 2.25rem;
			min-width: 2.25rem;
			font-size: 1rem;
			line-height: 1rem;
			padding: .5rem;
			border-radius: 3px;

			.p-inputtext {
				padding: 0 2.5em 0 0;
			}

			.p-dropdown-items {
				background-color: #FFF;
				padding: 0;

				.p-dropdown-item {
					background-color: #FFF;
					color: #333;
					padding: 5px 10px;

					&:hover {
						background-color: #f7f8fa;
					}
				}
			}

			&:hover {
				background: $cuco-brand-color;
				color: #fff;
			}

			&.p-focus {
				box-shadow: 0 0 transparent;
			}
		}


	}
}

//NG-Table
p-table th:last-child, p-table td:last-child {

	text-align: right;
}

p-table th:last-child {

	padding-right: 2%;
}

p-table td span.label {
	height: auto;
}
