
// External
app-external cuco-subheader {
  display: none !important;
}

.button-icon {
  vertical-align: baseline;
  font-size: 1rem;
}

// Dragula
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

// noUISlider Fixes
.noUi-tooltip {
  opacity: 0.7; //because it overlaps the label
}

// FullCalendar Fixes
.fc-button, .fc-button .fc-icon {
  color: $dark !important;
  border-color: $dark !important;
}

td .fc-today:not(.fc-day-top) {
  background: rgba($brand, 0.1) !important;
}

.fc-title {
  display: inline;
  white-space: pre-wrap !important;
}

// Select2 Fixes
.no-border-radius-right {
  .select2-selection {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.no-border-radius-left {
  .select2-selection {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}
.select2-selection__choice__remove::before {
  content: "" !important;
  font-family: "Line Awesome Free" !important;
  font-weight: 900 !important;
  font-size: 1rem !important;
}

.select2-selection__arrow::after {
  content: "" !important;
  font-family: "Line Awesome Free" !important;
  font-weight: 900 !important;
  font-size: 1rem !important;
}

.select2-selection {
  min-height: calc(1.3rem + 1.5em)
}

.is-invalid {
  .select2-selection {
    border-color: var(--danger);
  }
}

// Google Maps Fixes
.pac-container {
  z-index: 9999;
}

//ngbTooltip
.tooltip-success {
  .tooltip-inner {
    background-color: $success;
    color: $success-inverse;
  }
  .arrow::before {
    border-top-color: $success;
  }
}

// Metronic Fixes
.custom-file-label {
  position: relative;
  width: 100%;
}

.alert-elevate {
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
}

.switch.switch-brand:not(.switch-outline) input:empty ~ span::before {
  background-color: #e8ebf1;
}

.switch.switch-brand:not(.kt-switch--outline) input:checked ~ span::before {
  background-color: $cuco-brand-color;
}

.switch {
  /** TODO: fix box appearing while transitioning */
  &.switch-icon {
    input:checked ~ span:after {
      font-family: "Line Awesome Free";
      font-weight: 900;
      content: "";
      line-height: 0;
    }
  }
}

.text-muted {
  color: $dark-50 !important;
}

.nav-link:not(.active) {
  .nav-text {
    color: #595d6e !important;
  }
}

.nav-link:hover {
  .nav-text {
    color: $cuco-brand-color !important;
  }
}

.menu-item {
  .menu-icon, .menu-text {
    color: $dark-75 !important;
  }
}

.menu-item-open, .menu-item:hover {
  > .menu-link {
    > .menu-icon, > .menu-text {
      color: $cuco-brand-color !important;
    }
  }

}

.kt-link {
  color: $cuco-brand-color;

  &:hover {
    color: darken($cuco-brand-color, 7.5%);
  }
}

a {
  color: $body-color;
}

a:hover {
  color: darken($cuco-brand-color, 7.5%);
}

.nav .show > .nav-link, .nav .nav-link:hover:not(.disabled), .nav .nav-link.active {
  color: $cuco-brand-color;
}

.btn-clean {
  &:hover, &:focus, &:active {
    color: $cuco-brand-color !important;

    &:not(.btn-text) i {
      color: $cuco-brand-color !important;
    }
  }
}

// BsDatepicker
.bs-datepicker-head {
  background-color: $cuco-brand-color !important;
}

.bs-datepicker-body table {
  td .bs-datepicker-today {
    background-color: $highlighted !important;
    //border-width: 1px;
    //border-style: solid;
    //border-color: $cuco-brand-color;
  }

  td span.selected,
  td.selected span,
  td span[class*="select-"]:after,
  td[class*="select-"] span:after {
    background-color: $cuco-brand-color !important;
  }

  td.week span {
    color: $cuco-brand-color !important;
  }

  td.active-week span:hover {
    cursor: pointer;
    background-color: $cuco-brand-color;
    color: #fff;
    opacity: 0.5;
    transition: 0s;
  }
}

.bs-datepicker-predefined-btns button.selected {
  background-color: $cuco-brand-color;
}


/* FIXES */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: initial;
}

/* CARD */

.card-no-shadow{

  box-shadow: none!important;
}

.slick-slide, .slide-slide *, .slick-slide:focus, slick-slide:focus *{

  outline-color:transparent;
  outline:none;
}

/* hide dots if not necessary */
.slick-dots {
  > li {
    display: inline-block;
  }
  > li:only-child {
    display: none;
  }
}

.slick-dots li button:before {
  content: "";
  width: 26px;
  height: 12px;
  background: #FFFFFF;
  border-radius: 6px;
  opacity: 1;
}

.slick-dots li.slick-active button:before{

  background: $brand;

}

/* the parent */
.slick-list {
  margin: 0 -12.5px;
}

/*tmp fix for ck5 is stripping styles*/
.job-img{ width:100%!important; height:auto;}

