//
// Tooltip Mixin
//

@mixin tooltip-theme($color, $bg-color) {
	.tooltip-inner {
		color: $color;
		background-color: $bg-color;
	}

	&.bs-tooltip-top {
		.arrow::before {
			border-top-color: $bg-color;
		}
	}

	&.bs-tooltip-right {
		.arrow::before {
			border-right-color: #{$bg-color}#{'/*rtl:ignore*/'};
		}
	}

	&.bs-tooltip-bottom {
		.arrow::before {
			border-bottom-color: $bg-color;
		}
	}

	&.bs-tooltip-left {
		.arrow::before {
			border-left-color: $bg-color#{'/*rtl:ignore*/'};
		}
	}
}
