// #####################
// CUCO CONFIG VARIABLES
// #####################

@import "app/generated_custom_styles.scss";


$cuco_content_background-color: #FFF; //#FFF

$cuco_body_background-color: #f4f5f6; //#f2f3f8

$cuco_footer_background-color: #FFF; //#FFF


//Menu
$cuco_menu_background-color: #FFF; //#FFF
$cuco_menu-hover_background-color: #f6f7fd; //#f6f7fd
$cuco_menu-hover_font-color: #f6f7fd; //#f6f7fd
$cuco_menu-active_background-color: #f6f7fd;//#f6f7fd
$cuco_menu-hover_font-color: #f6f7fd;//#f6f7fd

// #####################
// Changes to Metronic Theme
// #####################

$kt-content-bg-color: $cuco_body_background-color;

$kt-layout-skins: (
	dark: #1e1e2d,
	light: $cuco_menu_background-color
);

// #####################
// Changes to Third Party
// #####################

.fc-view {
	z-index: 0;
}
