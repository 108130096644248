//
// radio
//


// Base radio
.radio {
	display: inline-block;
	position: relative;
	padding-left: 25px;
	text-align: left;
	cursor: pointer;
	font-size: 1rem;
	transition: get($radio-config, transition);
	margin: 0;

	&:hover > input:not([disabled]) ~ span,
	> input:focus ~ span {
		transition: get($radio-config, transition);
	}

	&.radio-disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	// Hide default browser input
	> input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	> span {
		border-radius: 50%;
		background-color: none;
		position: absolute;
		top: 1px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: '';
			display: none; // Hide check
			width: get($radio-config, tick);
			height: get($radio-config, tick);
			border-radius: 100% !important;
		}
	}

	> input:checked ~ span {
		transition: get($radio-config, transition);
		background-color: none;

		&:after {
			display: block;
		}
	}

	// Outline Style
	&.radio-outline {
		> span {
			border-width: 1px;
			border-style: solid;
		}
	}

	&.radio-outline-2x {
		> span {
			border-width: 2px !important;
		}
	}

	// Square Style
	&.radio-square {
		> span {
			border-radius: 0;
		}
	}

	// Rounded Style
	&.radio-rounded {
		> span {
			@include border-radius($border-radius);
		}
	}

	// Single mode
	&.radio-single {
		> span {
			top: 0px;
		}

		th > &,
		td > & {
			right: -5px;
		}
	}

	// Input group
	.input-group & {
		margin-bottom: 0 !important;
		padding-left: 0;
	}

	// Form integration
	.form-inline & {
		margin-left: 15px;
		margin-right: 15px;
	}
}

// List radio
.radio-list {
	display: flex;
	flex-direction: column;

	.radio {
		margin-bottom: 0.75rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Configure radio
.radio {
	// Sizes
	@include radio-size(get($radio-config, sizes, default, base), get($radio-config, sizes, default, tick));

	&.radio-lg {
		@include checkbox-size(get($radio-config, sizes, lg, base), get($radio-config, sizes, lg, tick));
	}

	// Default theme
	@include radio-solid-theme(get($radio-config, color), $primary, $white);

	&.radio-outline {
		@include radio-outline-theme(get($radio-config, outlineColor), $primary, $primary);
	}

	// Color options
	@each $name, $color in $theme-colors {
		// Default style
		&.radio-#{$name} {
			@include radio-solid-theme(get($radio-config, color), $color, $white);
		}

		// Light style
		&.checkbox-light-#{$name} {
			@include checkbox-solid-theme(theme-light-color($name), $color, theme-inverse-color($name));
		}

		// Outline style
		&.radio-outline.radio-#{$name} {
			@include radio-outline-theme(get($radio-config, outlineColor), $color, $color);
		}
	}
}

// Inline radio
.radio-inline {
	display: flex;

	.radio {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}
}
