//
// Browsers
//

@mixin for-ie11 {
	// Microsoft IE11
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}

@mixin for-edge {
	// Microsoft Edge
	@supports (-ms-ime-align:auto) {
		@content;
	}
}
