//
// Progress
//


.progress {
	&.progress-xs {
		height: $progress-height-xs;

		.progress-bar {
			border-radius: $border-radius-sm;
		}
	}

	&.progress-sm {
		height: $progress-height-sm;

		.progress-bar {
			border-radius: $border-radius-sm;
		}
	}

	&.progress-lg {
		height: $progress-height-lg;

		.progress-bar {
			border-radius: $border-radius-lg;
		}
	}
}
