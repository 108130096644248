//
// SVG
//


// Base
.svg {
	@each $name, $color in $theme-colors {
		&.svg-fill-#{$name} {
			fill: $color !important;
		}

		&.svg-fill-light-#{$name} {
			fill: theme-light-color($name) !important;
		}
	}
}
